<template>
    <v-container>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card class="mx-auto px-2 pt-2">
            <!-- <v-btn @click="test">test</v-btn> -->
            <v-toolbar flat dark class="indigo lighten-3">
                <v-toolbar-title>Attendance Data *Sample only</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <div class="text-center pt-2">
                    <v-pagination
                        v-model="page"
                        :length="pageCount"
                        total-visible="10"
                        dark
                        @input="changePage()"
                    ></v-pagination>
                </div>   
                <!-- <v-btn 
                    class="lighten-2 my-2" 
                    color="red" 
                    block 
                    dark
                    @click="insertNewTimeInRecord()"
                >
                    time in *Insert new record
                </v-btn> -->

                <v-data-table
                    :headers="headers"
                    :items="attendanceData"
                    :loading="loading"
                    :search="search"
                    hide-default-footer
                >
                    <template v-slot:top>                     
                        <v-text-field
                            outlined
                            dense
                            prepend-inner-icon="mdi-magnify"
                            label="Search by text..."
                            v-model="search"
                        ></v-text-field>
                        <v-file-input
                            outlined
                            dense
                            label="Import Daily Time Record Excel..."
                            placeholder="Import Daily Time Record Excel..."
                            v-model="dailyTimeRecordExcel"
                            @change="importDailyTimeRecord()"
                        ></v-file-input>
                        <v-row>
                            <v-col cols="12">
                                <v-btn
                                    block
                                    dark
                                    color="blue"
                                    @click="viewImportSequences()"
                                    class="my-2"
                                >
                                    <v-icon>mdi-eye</v-icon>
                                    View Import Sequences
                                </v-btn>    
                            </v-col>
                        </v-row>                    
                    </template>
                    <template v-slot:[`item.created_at`]="{ item }">
                        {{ item.created_at | formatDate }}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon
                            color="orange"
                            @click="openEditRecordDialog(item)"
                        >
                            mdi-pencil
                        </v-icon>
                        <v-icon
                            color="red"
                            @click="deleteRecord(item)"
                        >
                            mdi-delete
                        </v-icon>
                    </template>
                </v-data-table>
                <div class="text-center pt-2">
                    <v-pagination
                        v-model="page"
                        :length="pageCount"
                        total-visible="10"
                        dark
                        @input="changePage()"
                    ></v-pagination>
                </div>
            </v-card-text>
        </v-card>

        <!--importSequencesDialog start-->
        <v-dialog v-model="importSequencesDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title
                        >Import Sequences</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="importSequencesDialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-data-table
                            :headers="importSequenceHeaders"
                            :items="importSequences"
                        >
                            <template v-slot:[`item.created_at`]="{item}">
                                {{ item.created_at | formatDate }}
                            </template>
                            <template v-slot:[`item.actions`]="{item}">
                                <v-icon
                                    color="red"
                                    @click="deleteSequence(item)"
                                >mdi-delete</v-icon>
                                <v-btn
                                    dark
                                    color="green"
                                    @click="generateAttendanceDataExcel(item)"
                                    class="my-2"
                                >
                                    <v-icon>mdi-microsoft-excel</v-icon>
                                    Generate Report
                                </v-btn>                                    
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-container>
            </v-card>
        </v-dialog>
        <!--importSequencesDialog end-->        
        <!--editRecordDialog start-->        
        <v-dialog v-model="editRecordDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title
                        >{{ editRecordDialogTitle }}</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="editRecordDialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-menu
                                    v-model="menu0"
                                    :close-on-content-click="false"
                                    max-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">                                  
                                        <v-text-field
                                            dense
                                            outlined
                                            label="Date"
                                            placeholder="Date"
                                            v-model="date"
                                            v-on="on"
                                            prepend-icon="mdi-calendar"
                                        ></v-text-field>   
                                    </template>
                                    <v-date-picker
                                        v-model="date"
                                        @change="menu0 = false"
                                    ></v-date-picker>
                                </v-menu>                                                             
                            </v-col>
                        </v-row>
                        <v-row>                   
                            <v-col cols="6">
                                <v-menu
                                    v-model="menu1"
                                    :close-on-content-click="false"
                                    max-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">                                
                                        <v-text-field
                                            dense
                                            outlined
                                            label="In(Date)"
                                            placeholder="In(Date)"
                                            v-model="in_date"
                                            prepend-icon="mdi-calendar"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="in_date"
                                        @change="menu1 = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="6">
                                <v-menu
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    max-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">                                
                                        <v-text-field
                                            dense
                                            outlined
                                            label="In(Time)"
                                            placeholder="In(Time)"
                                            v-model="in_time"
                                            prepend-icon="mdi-clock-time-eight-outline"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker
                                        v-model="in_time"
                                        use-seconds
                                        @change="menu2 = false"
                                    ></v-time-picker>
                                </v-menu>
                            </v-col>                              
                        </v-row>                                                                                                
                        <v-row>                   
                            <v-col cols="6">
                                <v-menu
                                    v-model="menu3"
                                    :close-on-content-click="false"
                                    max-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">                                
                                        <v-text-field
                                            dense
                                            outlined
                                            label="Out(Date)"
                                            placeholder="Out(Date)"
                                            v-model="out_date"
                                            prepend-icon="mdi-calendar"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="out_date"
                                        @change="menu3 = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="6">
                                <v-menu
                                    v-model="menu4"
                                    :close-on-content-click="false"
                                    max-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">                                
                                        <v-text-field
                                            dense
                                            outlined
                                            label="Out(Time)"
                                            placeholder="Out(Time)"
                                            v-model="out_time"
                                            prepend-icon="mdi-clock-time-eight-outline"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker
                                        v-model="out_time"
                                        use-seconds
                                        @change="menu4 = false"
                                    ></v-time-picker>
                                </v-menu>
                            </v-col>                              
                        </v-row>                                                                                                
                    </v-card-text>
                    <v-card-actions>
                        <v-btn 
                            block 
                            color="red" 
                            class="lighten-2"
                            dark
                            @click="updateAttendanceData()"
                        >
                        <v-icon>mdi-update</v-icon>
                        Update</v-btn>
                    </v-card-actions>
                </v-container>
            </v-card>
        </v-dialog>
        <!--editRecordDialog end-->        
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            overlay: false,
            date: null,
            in_date: null,
            in_time: null,
            out_date: null,
            out_time: null,
            menu0: false,
            menu1: false,
            menu2: false,
            menu3: false,
            menu4: false,
            currentId: null,
            dailyTimeRecordExcel: [],
            headers: [
                {
                    text: "UUID",
                    value: "uuid",
                    sortable: false,
                },
                {
                    text: "Date",
                    value: "date",
                    sortable: false,
                },
                {
                    text: "Day",
                    value: "day",
                    sortable: false,
                },
                {
                    text: "EmployeeCode",
                    value: "employee_code",
                    sortable: false,
                },
                {
                    text: "EmployeeName",
                    value: "employee_name",
                    sortable: false,
                },
                {
                    text: "In",
                    value: "in",
                    sortable: false,
                },
                {
                    text: "Out",
                    value: "out",
                    sortable: false,
                },
                {
                    text: "In2",
                    value: "in2",
                    sortable: false,
                },
                {
                    text: "Out2",
                    value: "out2",
                    sortable: false,
                },
                {
                    text: "NextDay",
                    value: "next_day",
                    sortable: false,
                },
                {
                    text: "Hrs Work",
                    value: "hours_work",
                    sortable: false,
                },
                {
                    text: "Actions",
                    value: "actions",
                    sortable: false,
                },
                {
                    text: "CreatedAt",
                    value: "created_at",
                    sortable: false,
                },
            ],

            formData: null,
            editRecordDialog: false,
            attendanceData: [],
            loading: false,
            search: null,
            page: 1,
            pageCount: 0,
            itemsPerPage: 5,
            totalNumberOfRecords: null,
            editRecordDialogTitle: null,

            importSequencesDialog: false,
            importSequenceHeaders: [
                {
                    text: "UUID",
                    value: "uuid",
                    sortable: false,
                },                
                {
                    text: "FileName",
                    value: "file_name",
                    sortable: false,
                },                
                {
                    text: "CreatedAt",
                    value: "created_at",
                    sortable: false,
                },                
                {
                    text: "Actions",
                    value: "actions",
                    sortable: false,
                },                
            ],
            importSequences: [],
        }
    },
    mounted() {
        if( this.$store.state.user.role !== 'su' && this.$store.state.user.role !== 'hrsu' && this.$store.state.user.role !== 'hrassistant' ) {
            alert('You have no access right to this page...');
            this.$router.push('/transactions');
        }
        this.loadAttendanceData();
    },
    methods: {
        loadAttendanceData: function() {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/get_attendance_data',
                {
                    user: this.$store.state.user,
                    page: this.page,
                    itemsPerPage: this.itemsPerPage,
                    search: this.search,
                }
            ).then(response => {
                    // console.log(response.data);
                    if(response.status === 200) {
                        this.attendanceData = response.data.data;
                        this.totalNumberOfRecords = response.data.total;
                        this.pageCount = Math.ceil(response.data.total / this.itemsPerPage);
                    } else {
                        this.$store.commit('logOut');
                    }

                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });
        },
        changePage: function() {
            this.loadAttendanceData();
        },
        insertNewTimeInRecord: function() {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/insert_new_time_in_record',
                {
                    employee_code: this.$store.state.user.id,
                }
            ).then(response => {
                    // console.log(response.data);
                    if(response.status === 200) {
                        this.loadAttendanceData();
                    } else {

                    }

                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });
        },
        deleteRecord: function(item) {
            if(!confirm('Is it ok to delete the record: ' + item.id + '?')) {
                return false;
            }
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/delete_record',
                {
                    id: item.id,
                }
            ).then(response => {
                    console.log(response.data);
                    if(response.status === 200) {
                        this.loadAttendanceData();
                    } else {

                    }

                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });            
        },
        generateAttendanceDataExcel: function(item) {
            axios.post(
                '/api/generate_attendance_data_excel',
                {
                    uuid: item.uuid,
                }
            ).then(response => {
                // console.log(response.data['file'])
                // console.log(this.dates)
                // return false;
                this.downloading = false;
                this.overlay = false;
                const fileLink = document.createElement("a");
                fileLink.href = response.data['file'];
                fileLink.download = 'AttendanceData' + moment().format('YYYYMMDD') + '.xlsx';
                fileLink.target = "_blank";
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
            }).catch(error => {
                console.log(error);
            })
        },
        getDate: function(value) {
            if(value) {
                return value.substring(0, 10);
            }
        },
        openEditRecordDialog: function(item) {
            this.editRecordDialog = true;
            this.editRecordDialogTitle = 'Edit Record: ' + item.id; 
            this.currentId = item.id;
            this.date = item.date;
            this.in_date = item.in ? item.in.substring(0, 10): null;
            this.in_time = item.in ? item.in.substring(11, 19): null;
            this.out_date = item.out ? item.out.substring(0, 10): null;
            this.out_time = item.out ? item.out.substring(11, 19): null;
        },
        updateAttendanceData: function() {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/update_attendance_data',
                {
                    id: this.currentId,
                    date: this.date,
                    in_date: this.in_date,
                    in_time: this.in_time,
                    out_date: this.out_date,
                    out_time: this.out_time,
                }
            ).then(response => {
                    // console.log(response.data);
                    this.editRecordDialog = false;
                    this.loadAttendanceData();
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });             
        },
        importDailyTimeRecord: function() {
            this.formData = new FormData();
            if( typeof this.dailyTimeRecordExcel === 'undefined' || this.dailyTimeRecordExcel?.length === 0 ) {
                return false;
            }            
            this.formData.append("file", this.dailyTimeRecordExcel);
            this.overlay = true;
            this.loading = true;
            this.axios
                .post(
                "/api/import_daily_time_record",
                this.formData,
                {
                    headers: {
                    "Content-Type": "multipart/form-data",
                    },
                }
                )
                .then((response) => {
                if (response.status === 200) {
                    this.loadAttendanceData();
                    this.dailyTimeRecordExcel = undefined;
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        viewImportSequences: function() {
            this.importSequencesDialog = true;
            this.getImportSequences();
        },
        getImportSequences: function() {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/get_import_sequences',
            ).then(response => {
                    if(response.status === 200) {
                        this.importSequences = response.data;
                    }
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });            
        },
        deleteSequence: function(item) {
            // console.log(item.uuid)
            if( !confirm('Is it ok to delete the sequence?') ) {
                return false;
            }
            this.loading = true;
            this.axios.post(
                '/api/delete_sequence',
                {
                    uuid: item.uuid,
                }
            ).then(response => {
                    if(response.status === 200) {
                        this.importSequencesDialog = false;
                        this.getImportSequences();
                        this.loadAttendanceData();
                    }
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });                
        }
    },
    filters: {
        getDay: function(value) {
            const date = new Date(value);
            // console.log(date)
            const dayOfWeek = date.getDay() * 1;
            return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][dayOfWeek];
        },
        formatDate: function (date) {
            return date !== null ? moment(date).format("MM-DD-YYYY hh:mm:ss A") : '';
        }        
    }
}
</script>
